angular.module('MyHippoProducer.Controllers').directive('policyInformationSection', function () {
    return {
        restrict: 'E',
        scope: {},
        templateUrl: 'pages/policy-details/sections/policy-information/policy-information.html',
        controller: function ($scope, WorkflowUtil, PolicyService) {
            const { address, policyNumber, customer } = PolicyService.getPolicyOverview();
            $scope.policyInfo = PolicyService.policyInfo;
            const statusMapping = {
                pending_active: 'Pending Active',
                active: 'Active',
                terminated: 'Terminated',
                expired: 'Expired',

            };
            const productMap = {
                ho3: 'HO-3',
                ho5: 'HO-5',
                ho6: 'HO-6',
                dp3: 'DP-3',
            };
            $scope.policyOverview = {
                address,
                client: customer,
                policyNumber,
                policyType: productMap[$scope.policyInfo.product],
                effectiveDate: $scope.policyInfo.effective_date,
                effectiveTime: $scope.policyInfo.effective_time,
                status: statusMapping[$scope.policyInfo.status],
                zillow: _.get($scope.policyInfo.property_data, 'zillow.url'),
                organization: $scope.policyInfo.organization_name,
                agent: $scope.policyInfo.producer_name,
            };
        }
    };
});
